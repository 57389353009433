document.addEventListener("DOMContentLoaded", function() {
  const flashMessagesContainer = document.getElementById('flash-messages');

  if (flashMessagesContainer) {
    const closeButtons = flashMessagesContainer.querySelectorAll('.flash-close-button');
    const timeoutDuration = 5000;

    setTimeout(() => {
      flashMessagesContainer.querySelectorAll('.alert').forEach(alert => {
        fadeOut(alert, () => {
          if (alert && alert.parentNode) {
            alert.parentNode.removeChild(alert);
          }

          if (flashMessagesContainer.querySelectorAll('.alert').length === 0) {
            flashMessagesContainer.parentNode.removeChild(flashMessagesContainer);
          }
        });
      });
    }, timeoutDuration);

    closeButtons.forEach(button => {
      button.addEventListener('click', function() {
        const flashMessageDiv = this.closest('.alert');
        if (flashMessageDiv) {
          fadeOut(flashMessageDiv, () => {
            flashMessageDiv.parentNode.removeChild(flashMessageDiv);

            if (flashMessagesContainer.querySelectorAll('.alert').length === 0) {
              flashMessagesContainer.parentNode.removeChild(flashMessagesContainer);
            }
          });
        }
      });
    });
  }

  function fadeOut(element, callback) {
    element.style.transition = "opacity 0.5s ease";
    element.style.opacity = 0;

    setTimeout(() => {
      callback();
    }, 500);
  }
});